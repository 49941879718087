import { Component, input } from '@angular/core';
import { Params, RouterLink } from '@angular/router';

@Component({
  selector: 'app-link-back',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './link-back.component.html',
  styleUrl: './link-back.component.scss',
})
export class LinkBackComponent {
  link = input<string>();
  queries = input<Params>();
  variety = input<'normal' | 'absolute'>('normal');
}
